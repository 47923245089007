import React, { useState } from "react";
import { signIn } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const LoginModal = ({isOpen, onClose}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signingIn, setSigningIn] = useState(false);
    const navigate = useNavigate();

    const signinUser = async () => {
        try {
            setSigningIn(true);
            const {nextStep} = await signIn({username: email, password: password});

            if (nextStep.signInStep === "DONE") {
                setSigningIn(false);
                toast.success("Signin Successful!");
                window.localStorage.setItem('re_loan_info', '1');
                window.localStorage.setItem('user_email', email);
                onClose();
                navigate("/dashboard");
            }
        } catch (err) {
            toast.error("Unable to Login! Please try again.");
            setSigningIn(false);
            console.log(err);
        }
    }

    if (!isOpen) return null;

    return (
        <div
            className="tw-fixed tw-font-oswald tw-inset-0 tw-bg-black tw-border-white tw-shadow-xl tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50"
            onClick={onClose}
        >
            <div
                className="tw-bg-black tw-text-center tw-p-5 tw-rounded-xl tw-w-full md:tw-w-1/2 lg:tw-w-1/3"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <div className="tw-flex tw-items-center tw-flex-col tw-justify-center">
                    <span className='tw-bg-logo-image tw-bg-center tw-flex tw-justify-center tw-w-full tw-h-16 tw-bg-contain tw-bg-no-repeat'></span>
                    <h2 className="tw-text-xl tw-text-white tw-mb-4">
                        Sign In
                    </h2>
                </div>

                <div className="tw-flex tw-flex-col tw-space-y-4">
                    <input
                        type="email"
                        name="email"
                        className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                        placeholder="Email Address"
                        onChange={event => setEmail(event.target.value)}
                    />
                    <input
                        type="password"
                        className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                        placeholder="Password"
                        onChange={event => setPassword(event.target.value)}
                    />
                    <button
                        className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded-xl tw-w-full"
                        onClick={signinUser}
                        disabled={signingIn}
                    >
                        SignIn
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LoginModal;