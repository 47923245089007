import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import Header from '../Header';
import ApplicationHistory from './borrower/ApplicationHistory';
import ChatInbox from './borrower/ChatInbox';
import ChatBox from '../../components/Chatbox';
import AnimatePulse from '../loaders/AnimatePulse';
import UserAndPropertyInfo from './lender/UserAndPropertyInfo';
import IncomingApplicants from './lender/IncomingApplicants';
import LoanCalculator from '../LoanCalculator';

function Dashboard() {
    const { user } = useContext(UserContext);

    const [showLoanCalculator, setShowLoanCalculator] = useState(false);

    const handleLoanCalculator = () => {
        setShowLoanCalculator(false);
    }
    
    return (
        <main>
            <Header />
            {
                user 
                ? user['user_type'] === 'lender' 
                    ? <div className='tw-flex tw-flex-col md:tw-flex-row tw-font-oswald tw-space-x-4 md:tw-justify-between tw-my-10'>
                        <div className='tw-flex-1'>
                            <UserAndPropertyInfo />
                        </div>

                        <div className='tw-flex-1'>
                            <IncomingApplicants />
                        </div>

                        <div className='tw-flex-1'>
                            <ChatBox />
                        </div>
                    </div>
                    : <div className='tw-flex tw-flex-col md:tw-flex-row tw-font-oswald tw-space-x-4 md:tw-justify-between tw-my-10'>
                        <div className='tw-flex-1'>
                            <ApplicationHistory openLoanCalculator={setShowLoanCalculator}/>
                        </div>
                        
                        <div className='tw-flex-1'>
                            <ChatInbox />
                        </div>

                        <div className='tw-flex-1'>
                            <ChatBox />
                        </div>

                        { showLoanCalculator 
                            ? 
                                <div
                                    className="tw-fixed tw-font-oswald tw-inset-0 tw-shadow-xl tw-bg-opacity-50 tw-bg-black tw-flex tw-items-center tw-justify-center tw-z-50"
                                >
                                    <LoanCalculator isModal={true} closeLoanCalculator={handleLoanCalculator}/>
                                </div>
                            : null 
                        }
                    </div>
                : <div className='tw-h-[100vh] tw-flex tw-justify-center tw-items-center'>
                    <AnimatePulse />
                </div>
            }
        </main>
    );
}

export default Dashboard;