import React, { useState } from "react";
import { signUp } from "aws-amplify/auth";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import toast from "react-hot-toast";

const SignupModal = ({ role, isOpen, onClose }) => {
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [signingUp, setSigningUp] = useState(false);

    const registerUser = async () => {
        try {
            if (password !== confirmPassword) {
                setError('Passwords do not match');
                return;
              } else {
                setError('');
            }

            if (!firstName || !lastName || !email|| !phoneNumber || !password || !confirmPassword) {
                setError("All Fields are Required!");
                return;
            }

            setSigningUp(true);
            const {userId} = await signUp({
                username: email,
                password: password,
                options: {
                    userAttributes: {
                        email: email,
                        phone_number: phoneNumber,
                        name: firstName + " " + lastName,
                        'custom:user_type': role,
                        'custom:FirstName': firstName,
                        'custom:LastName': lastName
                    }
                }
            })
            if (userId) {
                toast.success("User Signup Successful! Please LogIn.");
                onClose(true);
                setError("");
                setSigningUp(false);
            }
        } catch (err) {
            console.log(err, err.type);
            toast.error("Cannot Signup User.");
            setError("Cannot Signup User.");
            setSigningUp(false);
        }
    }

    if (!isOpen) return null;

    return (
        <div
            className="tw-fixed tw-font-oswald tw-inset-0 tw-bg-black tw-border-white tw-shadow-xl tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50"
            onClick={() => {
                onClose(false);
                setError("");
            }}
        >
            <div
                className="tw-bg-black tw-text-center tw-p-5 tw-rounded-xl tw-w-full md:tw-w-1/2 lg:tw-w-1/3"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <div className="tw-flex tw-items-center tw-flex-col tw-justify-center">
                    <span className='tw-bg-logo-image tw-bg-center tw-flex tw-justify-center tw-w-full tw-h-16 tw-bg-contain tw-bg-no-repeat'></span>
                    <h2 className="tw-flex-1 tw-text-xl tw-text-white tw-mb-4">
                        {role === 'borrower' ? 'Signup as Borrower' : 'Signup as Lender'}
                    </h2>
                </div>

                <div className="tw-flex tw-flex-col tw-space-y-4">
                    <div className="tw-flex tw-space-x-2">
                        <input
                            type="text"
                            name="name"
                            className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                            placeholder="First Name"
                            onChange={event => setFirstName(event.target.value)}
                        />
                        <input
                            type="text"
                            name="name"
                            className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                            placeholder="Last Name"
                            onChange={event => setLastName(event.target.value)}
                        />
                    </div>
                    <input
                        type="email"
                        name="email"
                        className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                        placeholder="Email Address"
                        onChange={event => setEmail(event.target.value)}
                    />
                    <PhoneInput 
                        placeholder="Enter Phone Number" 
                        value={phoneNumber} 
                        onChange={setPhoneNumber}
                        defaultCountry="US"
                    />
                    <input
                        type="password"
                        className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                        placeholder="Password"
                        onChange={event => setPassword(event.target.value)}
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                        placeholder="Confirm Password"
                        onChange={event => setConfirmPassword(event.target.value)}
                    />
                    {error && (
                        <p className="tw-text-red-500 my-2">
                            **{error}**
                        </p>
                    )}
                    <button
                        className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded-xl tw-w-full"
                        onClick={registerUser}
                        disabled={signingUp}
                    >
                        Signup
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SignupModal;