import React from 'react';
import { UserDataProvider } from './contexts/UserContext';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import Dashboard from './components/dashboards/Dashboard';
import PrivateRoute from './private-routes/PrivateRoute';
import { Toaster } from 'react-hot-toast';

Amplify.configure(config);

function App() {
  return (
    <UserDataProvider>
      <Toaster position='top-right' toastOptions={{ duration: 2000 }}/>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/dashboard' element={<PrivateRoute>
            <Dashboard />
          </PrivateRoute>}/>
        </Routes>
      </Router>
    </UserDataProvider>
  );
}

export default App;
