import React, { useContext } from "react";
import { ChatBubbleLeftIcon, DocumentChartBarIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/solid";
import { UserContext } from "../../../contexts/UserContext";
import PropertyImage from "../../../assets/images/property_placeholder.png";

function UserAndPropertyInfo () {
    const { user } = useContext(UserContext);

    return (
        <div className="tw-flex tw-h-full tw-flex-col tw-space-y-4 tw-bg-black tw-font-oswald tw-shadow-lg tw-text-center tw-rounded-xl lg:tw-rounded-none lg:tw-rounded-tr-3xl lg:tw-rounded-br-3xl tw-py-4">
            <div className="tw-flex tw-items-center tw-px-5 tw-space-x-12">
                <div className="tw-flex tw-flex-col">
                    <h1 className="tw-text-2xl tw-tracking-wide">{ user['last_name'] + "," + user['first_name'] }</h1>
                    <p className="tw-text-green-500 tw-text-xs">REPEAT RE-LOAN USER</p>
                </div>
                
                <div className="tw-flex tw-space-x-1">
                    <DocumentChartBarIcon className="tw-w-14 tw-h-10"/>
                    <DevicePhoneMobileIcon className="tw-w-14 tw-h-10"/>
                    <ChatBubbleLeftIcon className="tw-w-14 tw-h-10"/>
                </div>
            </div>

            <div className="tw-flex tw-flex-col tw-bg-[#686b76] tw-p-5 tw-w-11/12 tw-rounded-tr-xl tw-rounded-br-xl">
                <div className="tw-flex tw-space-x-2">
                    <p className="tw-uppercase">Credit Score:</p>
                    <p className="tw-uppercase tw-text-green-500">{ user.credit_score }</p>
                </div>

                <div className="tw-flex tw-space-x-2">
                    <p className="tw-uppercase">Properties Currently Financed:</p>
                    <p className="tw-uppercase tw-text-green-500">{ user.currently_financed_properties }</p>
                </div>

                <div className="tw-flex tw-space-x-2">
                    <p className="tw-uppercase">Properties Financed in Last 6mo:</p>
                    <p className="tw-uppercase tw-text-green-500">{ user.properties_financed_last_6mo }</p>
                </div>

                <div className="tw-flex tw-space-x-2">
                    <p className="tw-uppercase tw-text-green-500">In { user.current_standing } Standing</p>
                </div>
            </div>

            <div className="tw-flex tw-flex-col tw-items-center tw-bg-[#686b76] tw-p-5 tw-w-11/12 tw-rounded-tr-xl tw-rounded-br-xl tw-pb-8">
                <h1 className="tw-text-xl tw-uppercase tw-self-start">Property Info</h1>
                <div className="tw-flex tw-space-x-4">
                    <img src={PropertyImage} alt="property" className="tw-self-start tw-w-1/2 tw-drop-shadow-2xl tw-border-2 tw-border-[#4853e4]"/>

                    <div className="tw-flex-1 tw-flex tw-flex-col tw-space-y-2">
                        <h1 className="tw-text-xl tw-text-left">{ user.property_info.address }</h1>
                        <p className="tw-text-sm tw-text-left">{ user.property_info.value }</p>
                        <p className="tw-text-sm tw-text-left">{ user.property_info.description }</p>

                        <div className="tw-flex tw-text-left tw-space-x-2">
                            <p className="tw-text-sm">Sqft:</p>
                            <p className="tw-text-sm tw-text-green-500">{ user.property_info.area_sqft }</p>
                        </div>

                        <div className="tw-flex tw-text-left tw-space-x-2">
                            <p className="tw-text-sm">Built:</p>
                            <p className="tw-text-sm tw-text-green-500">{ user.property_info.built_year }</p>
                        </div>

                        <div className="tw-flex tw-text-left tw-space-x-2">
                            <p className="tw-text-sm">5yr Appreciation:</p>
                            <p className="tw-text-sm tw-text-green-500">+20%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAndPropertyInfo;