import React, { useContext } from "react";
import ApplicationItem from "./ApplicationItem";
import { UserContext } from "../../../contexts/UserContext";

function IncomingApplicants () {
    const { user } = useContext(UserContext);

    return (
        <div className="tw-bg-black tw-h-full tw-flex tw-flex-col tw-space-y-4 tw-shadow-lg tw-rounded-xl lg:tw-rounded-3xl tw-py-4">
            <div className="tw-flex tw-items-center tw-px-5 tw-space-x-12">
                <button className="tw-px-4 tw-uppercase tw-bg-[#686b76] tw-rounded-xl hover:tw-bg-gray-400">FILTER</button>

                <h1 className="tw-uppercase tw-text-2xl">Incoming applicants</h1>
            </div>

            {   
                user.incoming_applicants.length ?
                (user.incoming_applicants.slice().reverse()).map((applicantItem, index) => (
                    <ApplicationItem key={index} property={JSON.parse(applicantItem)}/>
                ))
                : <p className="tw-text-center">No Incoming Applications So Far!</p>
            }
        </div>
    )
}

export default IncomingApplicants;