import React from "react";
import { DocumentChartBarIcon } from "@heroicons/react/24/solid";

function ApplicationItem({ property }) {
    return (
        <div className={`tw-bg-[#686b76] tw-uppercase tw-flex tw-text-center tw-space-x-2 tw-rounded-xl tw-p-2 tw-mx-4 tw-h-full tw-cursor-pointer tw-font-oswald tw-items-center hover:tw-bg-[#4853e4]`}>
            <div className="tw-flex">
                { property.property_owner ? <DocumentChartBarIcon className="tw-h-6 tw-w-6"/> : null}
                <h1 className="tw-flex-1 tw-text-md">{ property.borrower ? property.borrower : null }</h1>
            </div>
            <h1 className="tw-flex-1 tw-text-md">{ property.property_description ? property.property_description : '-' }</h1>
            <h1 className="tw-flex-1 tw-text-md">{ property.property_value ? property.property_value : '-' }</h1>
            <h1 className="tw-flex-1 tw-text-md">{ property.property_address ? property.property_address : '-' }</h1>
        </div>
    )
}

export default ApplicationItem;