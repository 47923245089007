import { useState, useEffect, useContext } from "react";
import LoanCalculator from "./LoanCalculator";
import Header from "./Header";
import HomeInfoArea from "./HomeInfoArea";
import { UserContext } from "../contexts/UserContext";
import LoginModal from "./modals/LoginModal";
import SignupModal from "./modals/SignupModal";
import ValidationModal from "./modals/ValidationModal";

function Home() {
    const { user } = useContext(UserContext);
    const [showSignupFormModal, setShowSignupFormModal] = useState(false);
    const [showSigninFormModal, setShowSigninFormModal] = useState(false);
    const [showValidationModal, setShowValidationModal] = useState(false);
    
    const closeSigninFormModal = () => setShowSigninFormModal(false);

    const closeSignupFormModal = (success = false) => {
        setShowSignupFormModal(false)

        if (success) {
        setShowValidationModal(true);
        }
    };

    const handleLoanCalculator = (showSignUp = false) => {
        if (showSignUp) {
            setShowSigninFormModal(false);
            setShowSignupFormModal(true);
        }
    }

    const closeValidationModal = (success = false) => {
        setShowValidationModal(false);
        if (success) {
          setShowSigninFormModal(true);
        }
    };

    useEffect(() => {
        console.log(user);
    }, [user])

    return (
        <>
        <Header />
        
        <div className="tw-hidden lg:tw-flex home tw-space-x-2 tw-px-8">
            <div className="triangle"></div>
            
            <div className="real-estate"></div>
            
            <div className="tw-relative tw-w-8/12">
                <HomeInfoArea />
            </div>
        
            <div className="tw-relative tw-w-4/12">
                <LoanCalculator isModal={false} closeLoanCalculator={handleLoanCalculator}/>
            </div>
        </div>
        
        <div className="tw-flex lg:tw-hidden tw-justify-center tw-items-center">
            <h1 className="tw-text-3xl tw-font-oswald tw-text-[#4853e4]">COMING SOON ON MOBILE</h1>
        </div>

        {/* Render the SignUp Form Modal */}
        <SignupModal 
            role={"borrower"} 
            isOpen={showSignupFormModal} 
            onClose={closeSignupFormModal} 
        />
        
        {/* Render the SignIn Form Modal */}
        <LoginModal 
            isOpen={showSigninFormModal} 
            onClose={closeSigninFormModal} 
        />

        {/* Render the Validation Modal */}
        <ValidationModal isOpen={showValidationModal} onClose={closeValidationModal} />
        </>
    );
}

export default Home;