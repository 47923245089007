import React, { useState, useEffect, useContext } from 'react';
import '../styles/Header.css';
import LogoImage from "../assets/icons/logo.svg";
import UserIcon from "../assets/icons/usericon.svg";
import SignupOptionsModal from './modals/SignupOptionsModal';
import SignupModal from './modals/SignupModal';
import ValidationModal from './modals/ValidationModal';
import LoginModal from './modals/LoginModal';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AnimatePulse from './loaders/AnimatePulse';
import { UserContext } from '../contexts/UserContext';

function Header() {
  const navigate = useNavigate();
  const { setUser, resetUser } = useContext(UserContext);

  const [showSignupOptionsModal, setShowSignupOptionsModal] = useState(false); // State to control modal visibility
  const [showSignupFormModal, setShowSignupFormModal] = useState(false);
  const [showSigninFormModal, setShowSigninFormModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [signupRole, setSignupRole] = useState(''); // borrower or lender
  const [loggedIn, setLoggedIn] = useState(window.localStorage.getItem("re_loan_info") || 0);
  const [showLoader, setShowLoader] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState(window.localStorage.getItem("user_email") || "");

  const openSignupOptionsModal = () => setShowSignupOptionsModal(true);

  const closeSignupOptionsModal = (role) => {
    if (role) {
      setShowSignupFormModal(true);
    }
    setSignupRole(role);
    setShowSignupOptionsModal(false);
  };

  const closeSignupFormModal = (success = false) => {
    setShowSignupFormModal(false)

    if (success) {
      setShowValidationModal(true);
    }
  };

  const openSigninFormModal = () => {
    setSignupRole('');
    setShowSignupOptionsModal(false);
    setShowSignupFormModal(false);
    setShowSigninFormModal(true);
  }

  const closeSigninFormModal = () => setShowSigninFormModal(false);

  const closeValidationModal = (success = false) => {
    setShowValidationModal(false);
    if (success) {
      setShowSigninFormModal(true);
    }
  };

  const handleLogOut = async () => {
    try {
        await signOut();
        toast.success("Signed Out!");
        window.localStorage.removeItem("re_loan_info");
        window.localStorage.removeItem("user_email");
        resetUser();
        setUserName('');
        setUserEmail('');
        setLoggedIn(0);
        navigate("/");
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  const goToHome = () => {
    navigate("/");
  }

  const goToDashboard = () => {
    navigate("/dashboard");
  }
  
  const setUpUserContext = async () => {
    setShowLoader(true);
    try {
      const _user = await fetch('https://jrwnwwqjpi.execute-api.us-east-2.amazonaws.com/development/fetch-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "email": userEmail
        })
      })

      const _userFromRDS = await _user.json();
      const _jsonUser = JSON.parse(_userFromRDS.body);
      console.log(_jsonUser);
      setUser(_jsonUser);
      setUserName(_jsonUser.first_name);
      setSignupRole(_jsonUser.user_type);
      setShowLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error Fetching User Data!')
      setShowLoader(false); 
    }
  }

  useEffect(() => {
    if (userEmail && loggedIn) {
      setUpUserContext();
    }
  }, [userEmail, loggedIn])

  return (
    <>
      <header className="tw-flex tw-flex-col tw-space-y-5 lg:tw-space-y-0 lg:tw-flex-row tw-items-center tw-jutify-between header tw-mx-auto">
        <div className="tw-flex-1"></div>

        <div className='tw-flex tw-justify-center tw-cursor-pointer' onClick={goToHome}>
          <img src={LogoImage} className='tw-h-[100px] tw-w-[90px]' alt='re-loan logo' />
        </div>

        {
          showLoader 
          ? <div className='tw-flex-1 tw-flex tw-justify-end'><AnimatePulse /></div> 
          : <div className='tw-flex-1 tw-flex tw-justify-end tw-items-center'>
              <div className="tw-flex tw-flex-col tw-items-center">
                <img src={UserIcon} className='tw-h-[70px] tw-w-[70px]' alt='user icon' />
                {userName 
                  ? <div className="tw-text-white tw-text-xs tw-font-oswald">
                      <span>{ userName }</span>
                      <span className="mx-1">/</span>
                      <button onClick={handleLogOut}>SIGN OUT</button>
                    </div> 
                  : <div className="tw-text-white tw-text-xs tw-font-oswald">
                      <button onClick={openSigninFormModal}>SIGNIN</button>
                      <span className="mx-1">/</span>
                      <button onClick={openSignupOptionsModal}>SIGNUP</button>
                    </div>
                }
              </div>
            </div>
        }
      </header>
      {loggedIn 
        ? showLoader 
        ? <div className='tw-flex-1 tw-flex tw-justify-center'><AnimatePulse /></div> 
        : <div className='tw-flex tw-justify-center tw-font-oswald tw-text-[#4853e4] tw-cursor-pointer' onClick={goToDashboard}>
            <h2 className='tw-text-2xl'>{ `${signupRole.toLocaleUpperCase()} DASHBOARD` }</h2>
          </div> 
        : null}
      {/* Render the Signup Options Modal */}
      <SignupOptionsModal showSignupOptionsModal={showSignupOptionsModal} onClose={closeSignupOptionsModal} />

      {/* Render the Signup Form Modal */}
      <SignupModal role={signupRole} isOpen={showSignupFormModal} onClose={closeSignupFormModal} />

      {/* Render the SignIn Form Modal */}
      <LoginModal 
        isOpen={showSigninFormModal} 
        onClose={closeSigninFormModal} 
      />

      {/* Render the Validation Modal */}
      <ValidationModal isOpen={showValidationModal} onClose={closeValidationModal} />
    </>
  );
}

export default Header;
