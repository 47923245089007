export async function searchPlaceIndexForText(searchText) {
    const response = await fetch(
      `https://places.geo.us-east-2.amazonaws.com/places/v0/indexes/application-location/search/text?text=${encodeURIComponent(searchText)}&key=v1.public.eyJqdGkiOiIyYTg5MDlkMS03YzViLTQ2MzUtOWYwNS1kYzYyMzNhNDFiZTgifYo6a5AHfzw8hsE5Cela68qPri3ArsbgM5REZDA4hg4XcWuGLR1IzjCi7Vi4giy8aaLAJj2N793hhpa2gnqTSyPmF9OTCU9uAofdCLh5lL_qXn9VSq6WZ8v9I1-w73hFjoInlXko3rwNM9878NStaT5c3L2VNzXtWOiZhGZ9l3uqcLzk15XySf5-dXcpcECN9sXwp49Lp7BDE3u7DoCXVwl-Ke4SdTUeuZ5HaY5BX0sGkgMnM0k5Bfavwjr1yRq3lkeHzffu-Sj53BdAoLjHUKE5c-FUvJbzc6npjPgcTMM9X_mydYWCHxIN-U6Ge0ww2Z2bSIvlFqkHFyXwzJ7F6dY.NjAyMWJkZWUtMGMyOS00NmRkLThjZTMtODEyOTkzZTUyMTBi`
    );
  
    // Check if the response is successful
    if (!response.ok) {
      console.error(`API call failed with status: ${response.status}`);
      return [];
    }
  
    const data = await response.json();
  
    // Log the API response to see its structure
    console.log('API Response:', data);
  
    // Check if data.Results exists before mapping
    if (!data.Results || !Array.isArray(data.Results)) {
      console.error('Unexpected API response format:', data);
      return [];
    }
  
    // Map the returned places to an array of strings (addresses)
    return data.Results.map(result => result.Place.Label);
  }
  