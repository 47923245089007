import React, { createContext, useState } from 'react';

// Create a context
export const UserContext = createContext();

// Create a provider component
export const UserDataProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Function to reset the context
  const resetUser = () => {
    setUser(null); // Reset the data to null or initial state
  };

  return (
    <UserContext.Provider value={{ user, setUser, resetUser }}>
      {children}
    </UserContext.Provider>
  );
};
