// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container::-webkit-scrollbar {
    width: 5px;               /* Set the width of the scrollbar */
  }
  
.chat-container::-webkit-scrollbar-track {
    background: transparent;        /* Background of the scrollbar track */
  }

.chat-container::-webkit-scrollbar-thumb {
    background-color: #888;     /* Color of the scrollbar thumb */
    border-radius: 10px;        /* Roundness of the scrollbar thumb */
    border: 1px solid #888;  /* Padding around the thumb */
}

.chat-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;     /* Darker color on hover */
}`, "",{"version":3,"sources":["webpack://./src/styles/ChatBox.css"],"names":[],"mappings":"AAAA;IACI,UAAU,gBAAgB,mCAAmC;EAC/D;;AAEF;IACI,uBAAuB,SAAS,sCAAsC;EACxE;;AAEF;IACI,sBAAsB,MAAM,iCAAiC;IAC7D,mBAAmB,SAAS,qCAAqC;IACjE,sBAAsB,GAAG,6BAA6B;AAC1D;;AAEA;IACI,sBAAsB,MAAM,0BAA0B;AAC1D","sourcesContent":[".chat-container::-webkit-scrollbar {\n    width: 5px;               /* Set the width of the scrollbar */\n  }\n  \n.chat-container::-webkit-scrollbar-track {\n    background: transparent;        /* Background of the scrollbar track */\n  }\n\n.chat-container::-webkit-scrollbar-thumb {\n    background-color: #888;     /* Color of the scrollbar thumb */\n    border-radius: 10px;        /* Roundness of the scrollbar thumb */\n    border: 1px solid #888;  /* Padding around the thumb */\n}\n\n.chat-container::-webkit-scrollbar-thumb:hover {\n    background-color: #555;     /* Darker color on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
