import React, { useState } from 'react';
import SignupOptionsModal from './modals/SignupOptionsModal';
import HomeIcon1 from "../assets/icons/icon1.svg";
import HomeIcon2 from "../assets/icons/icon2.svg";
import HomeIcon3 from "../assets/icons/icon4.svg";
import HomeIcon4 from "../assets/icons/icon3.svg";

const HomeInfoArea = () => {
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const openSignupModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <div className="tw-h-full tw-w-full">
        <h2 className="tw-font-oswald md:tw-text-4xl tw-text-[#4853e4] tw-my-4 tw-font-bold tw-text-center">Real Estate Financing Made Easy</h2>

        <div className='home-info-area tw-flex tw-flex-col tw-w-full tw-p-8'>
          <div className='tw-flex tw-space-x-6'>
            <div className='tw-flex tw-flex-1 tw-space-x-2'>
              <div className='tw-flex tw-flex-col tw-space-y-1'>
                <p className='tw-font-oswald tw-text-md tw-text-[#4853e4] tw-font-bold'>Say Goodbye to High Broker Fees</p>
                <p className='tw-font-oswald tw-text-xs'>Fill out an application for $25 flat rate to be matched with out nextwork of trusted lenders</p>
              </div>
              <img src={HomeIcon1} alt='no brokerage' className='tw-h-24 tw-w-24'/>
            </div>
            <div className='tw-flex tw-flex-1 tw-space-x-2'>
              <div className='tw-flex tw-flex-col tw-space-y-1'>
                <p className='tw-font-oswald tw-text-lg tw-text-[#4853e4] tw-font-bold'>AI Research and Fraud Detection</p>
                <p className='tw-font-oswald tw-text-xs tw-font-normal'>Re-Loan employs AI to instantly gather and display data relevant to applicants and properties in addition to AI fraud and forgery detection</p>
              </div>
              <img src={HomeIcon2} alt='ai' className='tw-h-24 tw-w-24'/>
            </div>
          </div>
        </div>

        <div className='tw-px-10 tw-py-4'>
          <div className='tw-flex tw-space-x-6'>
            <div className='tw-flex tw-flex-1 tw-justify-between tw-space-x-2 tw-items-start'>
              <div className='tw-flex tw-flex-col tw-space-y-1'>
                <p className='tw-font-oswald tw-text-lg tw-text-[#4853e4] tw-font-bold'>Communicate with Lenders</p>
                <p className='tw-font-oswald tw-text-xs tw-font-normal'>Message lenders directly via the Re-Loan dashboard</p>
              </div>
              <img src={HomeIcon3} alt='communicate' className='tw-h-24 tw-w-24'/>
            </div>
            <div className='tw-flex tw-flex-1 tw-space-x-4'>
              <div className='tw-flex tw-flex-col tw-space-y-1'>
                <p className='tw-font-oswald tw-text-lg tw-text-[#4853e4] tw-font-bold'>Due Diligence</p>
                <p className='tw-font-oswald tw-text-xs tw-font-normal'>Re-Loan partners with trusted due-diligence providers to handle each property processed through our network at no additional cost</p>
              </div>
              <img src={HomeIcon4} alt='diligence' className='tw-h-24 tw-w-24'/>
            </div>
          </div>
        </div>

        <div className='tw-flex tw-flex-col tw-space-y-2 tw-mt-20 tw-mb-36 tw-w-1/2 tw-text-center tw-mx-auto tw-justify-center tw-items-center'>
          <p className='tw-font-oswald tw-leading-8 tw-text-2xl tw-text-white'>Revolutionizing the Process of Commercial Real-Estate Financing</p>
          <button onClick={openSignupModal} className='tw-bg-[#4853e4] tw-text-white tw-font-oswald tw-px-6 tw-py-2 tw-rounded-2xl'>SIGN UP</button>
        </div>
      </div>
      
      <SignupOptionsModal showSignupOptionsModal={showModal} onClose={closeModal} />
    </>
  );
};

export default HomeInfoArea;
