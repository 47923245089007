import { useContext, useEffect } from "react";
import HistoryItem from "./HistoryItem";
import { UserContext } from "../../../contexts/UserContext";

function ApplicationHistory({ openLoanCalculator }) {
    const { user } = useContext(UserContext);

    return (
        <div className="tw-bg-black tw-shadow-lg tw-text-center tw-rounded-xl lg:tw-rounded-none lg:tw-rounded-tr-3xl lg:tw-rounded-br-3xl tw-py-4">
            <div className="tw-flex tw-justify-around tw-mb-4">
                <h1 className="tw-font-oswald tw-text-white tw-text-2xl">APPLICATION HISTORY</h1>
                <button 
                    className="tw-font-oswald tw-text-white tw-bg-green-500 hover:tw-bg-green-800 tw-text-xs xl:tw-text-md tw-rounded-xl tw-px-4" 
                    onClick={() => openLoanCalculator(true)}
                >
                    NEW APPLICATION
                </button>
            </div>

            {
                user.application_history.length ? 
                (user.application_history.slice().reverse()).map((historyItem, index) => (
                    <HistoryItem key={index} property={JSON.parse(historyItem)}/>
                ))
                : <p>No Application Records Found! Please Apply for a New Loan.</p>
            }
        </div>
    );
}

export default ApplicationHistory;