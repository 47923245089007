import React from "react";

function HistoryItem({ property }) {
    return (
        <div className={`${property.property_status ? property.property_status : 'inactive_property'} tw-flex tw-text-center tw-space-x-4 tw-rounded-xl lg:tw-rounded-none lg:tw-rounded-tr-lg lg:tw-rounded-br-lg tw-py-3 tw-mr-4 tw-my-4 tw-h-full tw-cursor-pointer tw-font-oswald`}>
            <h1 className="tw-flex-1">{ property.property_description ? property.property_description : '-' }</h1>
            <h1 className="tw-flex-1">{ property.property_value ? property.property_value : '-' }</h1>
            <h1 className="tw-flex-1">{ property.property_address ? property.property_address : '-' }</h1>
        </div>
    )
}

export default HistoryItem;