import React from "react";

function ChatInbox() {
    const chats = [
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        },
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        },
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        },
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        },
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        },
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        },
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        },
        {
            chat_title: 'Avant Loans, LLC',
            chat_description: 'Inquiry regarding your application for 1234 Moonlight Lane in Chicago, IL'
        }

    ]

    return (
        <div className="tw-bg-black tw-h-full tw-flex tw-flex-col tw-space-y-4 tw-shadow-lg tw-rounded-xl lg:tw-rounded-3xl tw-py-4">
            <h1 className="tw-font-oswald tw-text-white tw-text-2xl tw-text-center">INBOX</h1>

            <div className="tw-bg-[#bbbbbb] tw-border-2 tw-mx-4 tw-border-black-500 tw-rounded-md tw-divide-y-2">
                {
                    chats.map((chat) => (
                        <div className="tw-font-oswald tw-p-2">
                            <h1 className="tw-text-xl tw-font-bold tw-text-black">{ chat.chat_title }</h1>
                            <p className="tw-text-xs tw-text-black">{ chat.chat_description }</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ChatInbox;