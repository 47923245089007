import React, { useState, useEffect, useContext } from 'react';
import '../styles/LoanCalculator.css';
import { searchPlaceIndexForText } from '../services/locationService';
import { UserContext } from '../contexts/UserContext';
import AnimatePulse from './loaders/AnimatePulse';
import { XCircleIcon } from "@heroicons/react/24/solid";
import toast from 'react-hot-toast';

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function LoanCalculator({ isModal, closeLoanCalculator }) {
  const { user, setUser } = useContext(UserContext);

  const [loggedIn, setLoggedIn] = useState(window.localStorage.getItem("re_loan_info") || 0);
  const [loanPurpose, setLoanPurpose] = useState('');
  const [propertyValue, setPropertyValue] = useState(2140000);
  const [loanToValue, setLoanToValue] = useState(50000);
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const updateUserContext = async () => {
    setShowLoader(true);
    try {
      const _user = await fetch('https://jrwnwwqjpi.execute-api.us-east-2.amazonaws.com/development/fetch-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "email": user.email
        })
      })

      const _userFromRDS = await _user.json();
      const _jsonUser = JSON.parse(_userFromRDS.body);
      console.log(_jsonUser);
      setUser(_jsonUser);
      setShowLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error Fetching User Data!')
      setShowLoader(false);
    }
  }

  const createBorrowerLoanApplication = async () => {
    setShowLoader(true);
    try {
      const _response = await fetch('https://jrwnwwqjpi.execute-api.us-east-2.amazonaws.com/development/create-loan-application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: user.id,
          propertyAddress: address,
          propertyValue: propertyValue,
          loanPurpose: loanPurpose,
          desiredLoanToValue: loanToValue
        })
      })

      const response = await _response.json();
      if (response.statusCode === 200) {
        toast.success("Application Submitted Successfully.");
        // re-fetch user and update context
        updateUserContext();
        closeLoanCalculator(false);
        setShowLoader(false);
      } else {
        toast.error("Application Submit Failure.");
        setShowLoader(false);
      }
    } catch (error) {
      console.error('Error submitting the loan application:', error);
      toast.error('Error Submitting the Loan Application.');
      setShowLoader(false);
    }
  }

  const getAQuote = async () => {
    if (loanPurpose && address) {
      if (loggedIn && user.user_type === 'borrower') {
        createBorrowerLoanApplication();      
      } else {
        // open signup for borrower
        closeLoanCalculator(true);
      }
    } else {
      toast.error("Please fill all the fields.");
    }
  }

  useEffect(() => {
    if (address.length > 2) {
      const fetchSuggestions = async () => {
        const result = await searchPlaceIndexForText(address);
        setSuggestions(result);
      };
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [address]);

  return (
    <div className={`tw-flex tw-flex-col tw-space-y-2 ${isModal ? 'tw-w-1/3' : 'tw-pt-8 tw-mb-48'}`}>
      {!isModal
        ? <p className='tw-text-2xl tw-text-[#4853e4] tw-font-oswald tw-text-center'>Loan Calculator</p>
        : null
      }
      <div className="calculator-container">
        {isModal
          ? <div className='tw-flex tw-items-center tw-justify-center tw-space-x-4 tw-pt-2'>
            <p className='tw-text-2xl tw-text-[#4853e4] tw-font-bold tw-font-oswald tw-text-center'>New Loan Application</p>
            <XCircleIcon className="tw-w-10 tw-h-8 tw-cursor-pointer" onClick={() => closeLoanCalculator(false)} />
          </div>
          : null
        }
        {
          showLoader ? <div className='tw-h-[90vh] tw-flex tw-justify-center tw-items-center'>
            <AnimatePulse />
          </div> : 
          <div className="calculator-form">
            <div className="form-group">
              <label className='tw-font-oswald'>Loan Purpose</label>
              <select className="dropdown" value={loanPurpose} onChange={(e) => setLoanPurpose(e.target.value)}>
                <option value="" className='tw-font-oswald'>-- Select --</option>
                <option value="PURCHASE" className='tw-font-oswald'>PURCHASE</option>
                <option value="REFINANCE" className='tw-font-oswald'>REFINANCE</option>
                <option value="CASH-OUT REFINANCE" className='tw-font-oswald'>CASH-OUT REFINANCE</option>
                <option value="REHAB" className='tw-font-oswald'>REHAB</option>
                <option value="CONSTRUCTION" className='tw-font-oswald'>CONSTRUCTION</option>
              </select>
            </div>
            <div className="form-group">
              <label className='tw-font-oswald'>Property Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="address-input"
              />
              {suggestions.length > 0 && (
                <ul className="suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => setAddress(suggestion)}>
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="form-group">
              <label className='tw-font-oswald'>Property Value</label>
              <input
                type="text"
                className='tw-font-oswald'
                min="1000000"
                max="30000000"
                step="1000000"
                value={propertyValue}
                onChange={(e) => setPropertyValue(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className='tw-font-oswald'>Desired Loan to Value</label>
              <input
                type="text"
                className='tw-font-oswald'
                min="0"
                max="100"
                step="1"
                value={loanToValue}
                onChange={(e) => setLoanToValue(e.target.value)}
              />
            </div>
            <div className="results tw-font-oswald tw-flex tw-flex-col tw-space-y-0">
              <h3 className='tw-text-[#4853e4] tw-text-xl'>
                Loan Amount: $
                {formatNumber(Math.round((propertyValue * loanToValue) / 1000))}
              </h3>
              <p>Potential Programs Available: 0</p>
              <p>Estimated Rate: 0%</p>
              <p>Potential Lenders: 0</p>
            </div>
            <div className="loan-amount">
              <button className='tw-font-oswald' onClick={getAQuote}>GET A QUOTE</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default LoanCalculator;
