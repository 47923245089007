import React, { useState, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import '../styles/ChatBox.css';

const ChatBox = () => {
    const { user } = useContext(UserContext);

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleSend = () => {
        if (inputValue.trim()) {
            setMessages([...messages, inputValue]);
            setInputValue('');
        }
    };

    // Function to detect Enter key press in the input field
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    return (
        <div className="tw-h-full tw-h-full tw-font-oswald tw-text-white tw-flex tw-justify-between tw-flex-col tw-bg-black tw-shadow-lg tw-text-center tw-rounded-xl lg:tw-rounded-none lg:tw-rounded-tl-3xl lg:tw-rounded-bl-3xl tw-py-4">
            <h1 className='tw-text-2xl tw-text-white tw-mb-4'>{ user.name }</h1>
            <div className="chat-container tw-flex-1 tw-flex tw-items-end tw-flex-col tw-max-h-[400px] tw-overflow-y-auto">
                {messages.map((message, index) => (
                    <div key={index} className="tw-bg-green-300 tw-text-lg tw-w-1/2 tw-p-2 tw-rounded-tl-md tw-rounded-bl-md tw-mb-2 tw-px-4 tw-font-oswald tw-justify-end">
                        {message}
                    </div>
                ))}
            </div>
            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                className="tw-bg-black tw-p-4 tw-mx-4 tw-text-white tw-rounded-xl focus:tw-border-0"
                placeholder="Press Enter to Send a message..."
            />
        </div>
    );
};

export default ChatBox;
